import {
    DriveFolderUpload as DriveFolderUploadIcon,
    NotificationsNone as NotificationsNoneIcon,
    PostAdd as PostAddIcon,
    Settings as SettingsIcon,
} from '@mui/icons-material'

import FactoryIcon from '../../assets/icons/SVG/FactoryIcon'
import { ROUTES } from '../utils/AppRoutes'
import { UserType } from '../utils/Types'

export type menuOptionsType = {
    icon: JSX.Element
    label: string
    path: string
    visible: boolean
}

export const menuOptions = (user: UserType): menuOptionsType[] => {
    return [
        {
            icon: <NotificationsNoneIcon />,
            label: 'Lançamento',
            path: ROUTES.NEWS,
            visible: !user.isDsm,
        },
        {
            icon: <FactoryIcon />,
            label: 'Fábricas',
            path: ROUTES.MILLSLIST,
            visible: !user.isDsm,
        },
        {
            icon: <DriveFolderUploadIcon />,
            label: 'Importação',
            path: ROUTES.IMPORT,
            visible: !user.isDsm,
        },
        {
            icon: <SettingsIcon />,
            label: 'Permissionamentos',
            path: ROUTES.APPPERMISSIONS,
            visible: (user?.isDsm && user?.isUserGroupsSTC) || false,
        },
        {
            icon: <PostAddIcon />,
            label: 'Regras comerciais',
            path: ROUTES.COMMERCIALRULES,
            visible: (user?.isDsm && user?.isUserGroupsSTC) || false,
        },
    ]
}

import { Check as CheckIcon } from '@mui/icons-material'
import { Button, FormHelperText } from '@mui/material'
import { styled as materialStyled } from '@mui/material/styles'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { COLORS } from '../../commons/Colors'
import FileUploaderComponent from '../../commons/components/FileUploaderComponent'
import FormInputComponent from '../../commons/components/FormInputComponent'
import LoadingComponent from '../../commons/components/LoadingComponent'
import MainHeaderComponent from '../../commons/components/MainHeaderComponent'
import RadioButtonComponent from '../../commons/components/RadioButtonComponent'
import SnackBarComponent from '../../commons/components/SnackBarComponent'
import SwitchButtonComponent from '../../commons/components/SwitchButtonComponent'
import { ROUTES } from '../../commons/utils/AppRoutes'
import { SnackbarConfigTypes } from '../../commons/utils/Types'
import { CommercialRulesForm, RuleType } from '../../models/CommercialRules'
import GlobalThemeProvider from '../../style/GlobalTheme'

export type CommercialRulesFormViewProps = {
    handleFormChange: (toUpdate: Partial<CommercialRulesForm>) => void
    handleSave: () => void
    handleCloseSnackbar: () => void
    snackbarConfig: SnackbarConfigTypes
    isEdition: boolean
    isLoading: boolean
    isSending: boolean
    commercialRule: CommercialRulesForm
    ruleTypes?: RuleType[]
    isValidForm: () => boolean
}
export default function CommercialRulesFormView({
    handleFormChange,
    snackbarConfig,
    handleCloseSnackbar,
    handleSave,
    isEdition,
    isLoading,
    isSending,
    commercialRule,
    isValidForm,
    ruleTypes,
}: CommercialRulesFormViewProps) {
    function getFileNameByPath() {
        return commercialRule.path?.split('/').pop()
    }
    return (
        <GlobalThemeProvider>
            <PageContainer>
                <MainHeaderComponent />
                <PageTitle>Regras comerciais - Time DSM-Firmenich</PageTitle>
                <FormContainer>
                    <ConfigLabel>Nova campanha</ConfigLabel>
                    <FormHelperText
                        style={{
                            marginTop: 20,
                            marginBottom: 10,
                            color: COLORS.secondaryGray,
                            fontWeight: 'lighter',
                        }}
                    >
                        * Informações obrigatórias
                    </FormHelperText>
                    <RuleFormContainer>
                        <ConfigLabel>* selecione a categoria</ConfigLabel>
                        <RadioButtonsContainer>
                            {ruleTypes?.map(({ name, id }) => {
                                return (
                                    <RadioButtonContainer key={name}>
                                        <RadioButtonComponent
                                            inputName={name}
                                            value={id}
                                            selectedValue={
                                                commercialRule.typeId
                                            }
                                            onChange={(typeId) =>
                                                handleFormChange({ typeId })
                                            }
                                        />
                                        <InputLabel>{name}</InputLabel>
                                    </RadioButtonContainer>
                                )
                            })}
                        </RadioButtonsContainer>
                    </RuleFormContainer>
                    <RuleFormContainer>
                        <FormInputComponent
                            label="* título do comunicado"
                            testid="form-input-name"
                            required
                            value={commercialRule.title}
                            onChange={(e: any) =>
                                handleFormChange({ title: e.target.value })
                            }
                            placeholder="Nova campanha desconto na fonte"
                            width={12000}
                        />
                        <ConfigItem>
                            <ConfigLabel>
                                disponibilidade do comunicado
                            </ConfigLabel>
                            <SwitchButtonComponent
                                testid="form-input-isActive"
                                active={commercialRule.active}
                                onClick={(isActive) =>
                                    handleFormChange({ active: isActive })
                                }
                            />
                            <ConfigDescription>
                                Comunicados ativos aparecerão para os usuários
                                no aplicativo
                            </ConfigDescription>
                        </ConfigItem>
                        <FileUploaderComponent
                            fileName={
                                isEdition ? getFileNameByPath() : undefined
                            }
                            onFilesSelected={(files) =>
                                handleFormChange({ file: files[0] })
                            }
                            label="* UPLOAD do ANEXO em pdf / IMAGEM (.JPG, .PNG) / Planilha (.xls,
                .xlsx, .csv)"
                        />
                    </RuleFormContainer>
                </FormContainer>
                <FormButtonsContainer>
                    <Link to={ROUTES.COMMERCIALRULES}>
                        <CancelButton variant="outlined" disabled={isLoading}>
                            Cancelar
                        </CancelButton>
                    </Link>
                    <SaveButton
                        startIcon={
                            isSending && <LoadingComponent size="small" />
                        }
                        onClick={handleSave}
                        disabled={!isValidForm() || isSending}
                        endIcon={<CheckIcon />}
                    >
                        Salvar
                    </SaveButton>
                </FormButtonsContainer>
                <SnackBarComponent
                    handleClose={handleCloseSnackbar}
                    isVisible={snackbarConfig.isVisible}
                    message={snackbarConfig.message}
                    severity={snackbarConfig.status}
                />
            </PageContainer>
        </GlobalThemeProvider>
    )
}

const FormButtonsContainer = styled.div`
    display: flex;
    width: -webkit-fill-available;
    justify-content: end;
    gap: 16px;
    align-items: center;
    padding: 0 32px 30px;
    margin-top: 9px;
`

const CancelButton = materialStyled(Button)({
    fontSize: 14,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.71,
    letterSpacing: 'normal',
    fontFamily: 'Roboto',
})

const SaveButton = materialStyled(Button)({
    fontSize: 14,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.71,
    letterSpacing: 'normal',
    backgroundColor: COLORS.primaryGreen,
    borderColor: COLORS.primaryGreen,
    color: COLORS.primaryWhite,
    fontFamily: 'Roboto',
    '&:hover': {
        backgroundColor: COLORS.secondaryGreen,
        borderColor: COLORS.secondaryGreen,
        boxShadow: 'none',
    },
    '&:disabled': {
        backgroundColor: COLORS.disabledButton,
        borderColor: COLORS.disabledButton,
        color: COLORS.primaryWhite,
        boxShadow: 'none',
    },
})

const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${COLORS.secondaryWhite};
`
const PageTitle = styled.h1`
    color: ${COLORS.primaryOrange};
    font-size: 18px;
    font-family: Roboto;
    font-weight: normal;
    padding: 24px;
`
const FormContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
    margin: 11px 32px;
    border: 1px solid ${COLORS.borderGray};
    border-radius: 3px;
    padding: 28px 23px 23px 22px;
    background-color: ${COLORS.primaryWhite};
`
const RuleFormContainer = styled.form`
    display: flex;
    flex-direction: column;
    padding: 28px 23px 23px 22px;
    border-radius: 3px;
    border: solid 1px ${COLORS.borderGray};
`
const ConfigItem = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    row-gap: 16px;
    padding: 20px 0px 20px 0px;
`
const ConfigDescription = styled.span`
    flex-grow: 0;
    font-family: Roboto;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: 0.09px;
    text-align: left;
    color: ${COLORS.primaryGray};
`
const ConfigLabel = styled.span<{
    formLabel?: boolean
}>`
    flex-grow: 0;
    font-family: Roboto;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: 0.09px;
    text-align: left;
    color: ${COLORS.primaryGray};
    text-transform: uppercase;
    ${({ formLabel }) =>
        formLabel &&
        `
        margin: 0px 0px 2px;
    `}
`
const FlexContainer = styled.div`
    display: flex;
`

const RadioButtonContainer = styled(FlexContainer)`
    align-content: center;
    align-items: center;
`
const RadioButtonsContainer = styled(FlexContainer)`
    gap: 10%;
`

const InputLabel = styled.label`
    color: ${COLORS.primaryGray};
    font-family: Roboto;
    font-size: 14px;
    line-height: 2;
`

import { useState } from 'react'
import styled from 'styled-components'

import { COLORS } from '../../commons/Colors'
import TabSelectorComponent, {
    UnmountedTabTypes,
} from '../../commons/components/TabSelectorComponent'
import BillingDateConfigScreen from './billingDateConfig/BillingDateConfigScreen'
import NfeEmissionConfigScreen from './nfeEmissionConfig/NfeEmissionConfigScreen'
import UnavailabilityConfigScreen from './unavailabilityConfig/UnavailabilityConfigScreen'

export default function DateManagementView() {
    const pageTabs: UnmountedTabTypes[] = [
        {
            label: 'Carregamento de pedidos',
            renderTabComponent: () => <BillingDateConfigScreen />,
        },
        {
            label: 'Período da PO',
            renderTabComponent: () => <NfeEmissionConfigScreen />,
        },
        {
            label: 'Indisponibilidade do Sistema',
            renderTabComponent: () => <UnavailabilityConfigScreen />,
        },
    ]

    const [selectedTab, setSelectedTab] = useState<UnmountedTabTypes>(
        pageTabs[0]
    )

    return (
        <PageContainer>
            <PageWrapper>
                <PageTitle>
                    EXIBIÇÃO DAS INFORMAÇÕES NO APLICATIVO FARMTELL MILLS
                </PageTitle>
            </PageWrapper>
            <TabSelectorComponent
                tabNames={pageTabs}
                getSelectedTab={setSelectedTab}
                isMainTab
            />
            {selectedTab && selectedTab.renderTabComponent()}
        </PageContainer>
    )
}

const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${COLORS.primaryWhite};
    border: solid 1px ${COLORS.borderGray};
    margin: 15px 15px 0px 15px;
`
const PageWrapper = styled.section`
    align-self: center;
    width: 97%;
`

const PageTitle = styled.h1`
    font-size: 12px;
    font-family: Roboto;
    font-weight: bold;
    align: left;
    text-align: left;
    margin-top: 25px;
    color: ${COLORS.primaryGray};
`

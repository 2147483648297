import dayjs, { Dayjs } from 'dayjs'
import { useEffect, useState } from 'react'

import { SnackbarConfigTypes } from '../../../commons/utils/Types'
import { BillingDateConfig } from '../../../models/BillingDateConfig'
import {
    getBilingDateConfig,
    setBilingDateConfig,
} from '../../AppPermissionsAPI'
import BillingDateConfigView from './BillingDateConfigView'

export default function BillingDateConfigContainer() {
    const [snackbarConfig, setSnackBarConfig] = useState<SnackbarConfigTypes>({
        message: '',
        isVisible: false,
        status: 'success',
    })
    const [isLoadingConfig, setIsLoadingConfig] = useState<boolean>(true)
    const [isSavingConfig, setIsSavingConfig] = useState<boolean>(false)
    const [billingDateConfig, setBillingDateConfig] =
        useState<BillingDateConfig>({
            acceptImmediateOrders: true,
            config: {},
        })

    useEffect(() => {
        loadConfig()
    }, [])

    async function loadConfig() {
        try {
            const { data } = await getBilingDateConfig()
            if (data) setBillingDateConfig(data)
        } catch (error) {
            setSnackBarConfig((prev) => ({
                ...prev,
                isVisible: true,
                message: `Erro ao buscar configuração de datas, por favor tente mais tarde!`,
                status: 'error',
            }))
        } finally {
            setIsLoadingConfig(false)
        }
    }

    const handleSaveConfig = async () => {
        try {
            setIsSavingConfig(true)
            await setBilingDateConfig(billingDateConfig)
            setSnackBarConfig((prev) => ({
                ...prev,
                isVisible: true,
                message: `Configuração salva com sucesso.`,
                status: 'success',
            }))
        } catch (error) {
            setSnackBarConfig((prev) => ({
                ...prev,
                isVisible: true,
                message: `Ocorreu um erro ao tentar salvar a configuração.`,
                status: 'error',
            }))
        } finally {
            setIsSavingConfig(false)
        }
    }

    const handleCloseSnackbar = () => {
        setSnackBarConfig((prev) => ({ ...prev, isVisible: false }))
    }

    const handleAcceptImmediate = (acceptImmediateOrders: boolean) => {
        setBillingDateConfig((crr) => ({ ...crr, acceptImmediateOrders }))
    }

    const getDateByMonthRef = (ref: string) => {
        return ref === 'current' ? dayjs() : dayjs().add(1, 'M')
    }

    const handleCustomConfig = ({
        period,
        value,
        monthRef,
    }: HandleConfigDay) => {
        const dateRef = value
            ? getDateRef(value)
            : getDateRef(getDateByMonthRef(monthRef))
        setBillingDateConfig((crr) => {
            const configToUpdate = crr.config[dateRef]
            const newConfig = {
                ...configToUpdate,
                [period]: value?.date() || undefined,
            }
            return {
                ...crr,
                config: { ...crr.config, [dateRef]: newConfig },
            }
        })
    }

    const handleDefaultConfig = ({ period, value }: HandleDefaultConfig) => {
        setBillingDateConfig((crr) => {
            const configToUpdate = crr.config.default
            const newConfig = { ...configToUpdate, [period]: value }
            return {
                ...crr,
                config: { ...crr.config, default: newConfig },
            }
        })
    }

    const getDateRef = (date: Dayjs): string => {
        const month = (date.month() + 1).toString()
        const year = date?.year().toString()
        return month + year
    }

    const makeDateFromRef = (date: Date, period: Period) => {
        const dateRef = getDateRef(dayjs(date))
        const existentConfig = billingDateConfig.config[dateRef]
        if (!existentConfig) return null
        const oneDigitMonthRef = dateRef.length === 5
        const month = Number(dateRef.slice(0, oneDigitMonthRef ? 1 : 2)) - 1
        const year = dateRef.slice(oneDigitMonthRef ? 1 : 2)
        const day = existentConfig[period]
        return new Date(Number(year), month, day)
    }

    const makeDaysOptions = () => {
        return new Array(31).fill(undefined).map((_, i) => {
            return { label: (i + 1).toString(), value: (i + 1).toString() }
        })
    }

    return (
        <BillingDateConfigView
            billingDateConfig={billingDateConfig}
            handleAcceptImmediate={handleAcceptImmediate}
            handleSaveConfig={handleSaveConfig}
            handleCloseSnackbar={handleCloseSnackbar}
            snackbarConfig={snackbarConfig}
            handleCustomConfig={handleCustomConfig}
            isLoadingConfig={isLoadingConfig}
            isSavingConfig={isSavingConfig}
            makeDateFromRef={makeDateFromRef}
            makeDaysOptions={makeDaysOptions}
            handleDefaultConfig={handleDefaultConfig}
        />
    )
}

export type HandleConfigDay = {
    period: Period
    value: Dayjs
    monthRef: 'current' | 'next'
}
export type HandleDefaultConfig = {
    period: Period
    value: number
}

export type Period = 'start' | 'end'

import { Autocomplete, TextField } from '@mui/material'

import { DropdownOptionTypes } from '../utils/Types'

export type BasicDropdownComponentProps = {
    options: DropdownOptionTypes[]
    onSelect: (option: DropdownOptionTypes) => any
    placeholder: string
    value: DropdownOptionTypes
    disabled?: boolean
    clearIcon?: boolean
    size?: 'small' | 'medium'
}

export default function BasicDropdownComponent({
    options,
    onSelect,
    placeholder,
    value,
    disabled = false,
    size = 'small',
    clearIcon = true,
}: BasicDropdownComponentProps): JSX.Element {
    function getValue(option: DropdownOptionTypes) {
        return Boolean(option?.label) && Boolean(option?.value) ? value : null
    }
    return (
        <Autocomplete
            clearIcon={clearIcon}
            size={size}
            options={options}
            renderInput={(params) => (
                <TextField {...params} label={placeholder} />
            )}
            onChange={(event, value) => {
                if (!value) return
                onSelect(value)
            }}
            isOptionEqualToValue={(option, value) =>
                option.value === value.value && option.label === value.label
            }
            value={getValue(value)}
            disabled={disabled}
        />
    )
}

import { Home as HomeIcon } from '@mui/icons-material'
import { Sidebar as Menu } from 'primereact/sidebar'
import { useState } from 'react'
import { MdMenu, MdPowerSettingsNew } from 'react-icons/md'
import styled from 'styled-components'

import { useUserContext } from '../../context/UserContext'
import { StorageKeys } from '../../models/ILocalStorageService'
import { COLORS } from '../Colors'
import { ROUTES } from '../utils/AppRoutes'
import { makeLocalStorageService } from '../utils/LocalStorageService'
import ItemMenu from './ItemMenu'
import { menuOptions } from './MenuOptions'
import UserProfileComponent from './UserProfileComponent'

export const SideMenu = () => {
    const { user } = useUserContext()
    const [isSideMenuVisible, setIsSideMenuVisible] = useState(false)
    const { removeItem } = makeLocalStorageService()

    const handleMenuVisibility = () => {
        setIsSideMenuVisible((isSideMenuVisible) => !isSideMenuVisible)
    }

    const onLogout = () => {
        removeItem(StorageKeys.USERTOKEN)
    }

    return (
        <>
            <MenuButtonContainer>
                <MenuButton
                    onClick={handleMenuVisibility}
                    isVisible={isSideMenuVisible}
                >
                    <MdMenu />
                </MenuButton>
            </MenuButtonContainer>
            <SideBar
                dismissable={false}
                showCloseIcon={false}
                modal={false}
                closeOnEscape={false}
                position="right"
                visible={isSideMenuVisible}
                onHide={handleMenuVisibility}
            >
                <SideBarContent>
                    <TopContainer>
                        <UserProfileComponent />
                        <ItemsContainer>
                            <ItemMenu
                                label="Início"
                                icon={<HomeIcon />}
                                linkTo={ROUTES.HOME}
                            />

                            {user &&
                                menuOptions(user).map((item) => (
                                    <ItemMenu
                                        key={item.label}
                                        label={item.label}
                                        icon={item.icon}
                                        linkTo={item.path}
                                        isVisible={item.visible}
                                    />
                                ))}
                        </ItemsContainer>
                    </TopContainer>

                    <BottomContainer>
                        <ItemMenu
                            label="Sair"
                            icon={<MdPowerSettingsNew size={25} />}
                            linkTo={ROUTES.LOGIN}
                            onClick={() => onLogout()}
                        />
                    </BottomContainer>
                </SideBarContent>
            </SideBar>
        </>
    )
}

const MenuButtonContainer = styled.div`
    &:hover {
        background-color: ${COLORS.veryLightPink};
    }
    border-radius: 50%;
    height: 40px;
`

const MenuButton = styled.button<{ isVisible: boolean }>`
    background-color: transparent;
    border-radius: 50%;
    cursor: pointer;
    color: ${(props) =>
        props.isVisible ? `${COLORS.primaryOrange}` : `${COLORS.black}`};
    border: none;
    display: flex;
    align-content: center;
    font-size: 30px;
    margin-top: 4px;
`

const SideBar = styled(Menu)`
    background-color: ${COLORS.primaryWhite};
    margin-bottom: 405px;
    height: 500px;
    padding: 37px 0 28px;
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.24);
`

const SideBarContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    border-left: 2px solid ${COLORS.borderGray};
`
const TopContainer = styled.div``

const ItemsContainer = styled.div``

const BottomContainer = styled.div`
    padding-top: 20px;
    border-top: 1px solid ${COLORS.lightBlueGreyTwo};
`

export default SideMenu

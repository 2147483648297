import Dialog from '@mui/material/Dialog'
import styled, { css } from 'styled-components'

import CloseCrossIcon from '../../assets/icons/SVG/CloseCrossIcon'
import { COLORS } from '../Colors'

export type CustomModalComponentProps = {
    onClose: () => void | null
    isVisible: boolean
    title: string
    children: JSX.Element | React.ReactNode
    titleStyle?: React.CSSProperties
    modalWidth?: string
    modalHeight?: string
}

function CustomModalComponent({
    onClose,
    isVisible,
    title,
    children,
    titleStyle,
    modalWidth = '504',
    modalHeight = 'auto',
}: CustomModalComponentProps): JSX.Element {
    return (
        <Dialog open={isVisible} maxWidth={false} onClose={onClose}>
            <ModalContainer
                data-testid="modal-container"
                modalWidth={modalWidth}
                modalHeight={modalHeight}
            >
                <ModalHeaderContainer>
                    <ModalHeaderTitleContainer>
                        <ModalHeaderTitle style={titleStyle}>
                            {title}
                        </ModalHeaderTitle>
                    </ModalHeaderTitleContainer>
                    <ModalHeaderCloseButtonContainer>
                        <ModalHeaderCloseButton
                            data-testid="CloseButton"
                            onClick={onClose}
                        >
                            <CloseCrossIcon />
                        </ModalHeaderCloseButton>
                    </ModalHeaderCloseButtonContainer>
                </ModalHeaderContainer>
                {children}
            </ModalContainer>
        </Dialog>
    )
}

const ModalContainer = styled.div<{ modalWidth: string; modalHeight: string }>`
    background-color: ${COLORS.primaryWhite};
    width: ${({ modalWidth }) => modalWidth};
    height: ${({ modalHeight }) => modalHeight};
    margin: auto;
    padding: 24px;

    ${(props) =>
        props.modalHeight === '100%' &&
        css`
            display: flex;
            flex-direction: column;
        `}
`

const ModalHeaderContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`
const ModalHeaderTitleContainer = styled.div`
    height: 'auto';
`
const ModalHeaderTitle = styled.span`
    color: ${COLORS.black};
    font-weight: bold;
    font-family: Roboto;
    font-size: 16px;
`
const ModalHeaderCloseButtonContainer = styled.div`
    padding: 5px 0px 0 0;
`

const ModalHeaderCloseButton = styled.button`
    background-color: ${COLORS.primaryWhite};
    border: none;
    color: ${COLORS.black};
    cursor: pointer;
`

export default CustomModalComponent

import ChevronLeft from '@mui/icons-material/ChevronLeft'
import ChevronRight from '@mui/icons-material/ChevronRight'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import {
    DatePicker,
    LocalizationProvider,
    DateTimePicker,
} from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { PickersCalendarHeaderProps } from '@mui/x-date-pickers/PickersCalendarHeader'
import dayjs, { Dayjs } from 'dayjs'
import { useCallback, useMemo } from 'react'

import { COLORS } from '../Colors'

require('dayjs/locale/pt-br')

function CustomCalendarHeader(props: PickersCalendarHeaderProps<Dayjs>) {
    const { currentMonth, onMonthChange } = props

    const selectNextMonth = useCallback(
        () => onMonthChange(currentMonth.add(1, 'month'), 'left'),
        [currentMonth, onMonthChange]
    )

    const selectPreviousMonth = useCallback(
        () => onMonthChange(currentMonth.subtract(1, 'month'), 'right'),
        [currentMonth, onMonthChange]
    )

    return (
        <CustomCalendarHeaderRoot>
            <Stack spacing={1} direction="row">
                <IconButton onClick={selectPreviousMonth} color="primary">
                    <ChevronLeft />
                </IconButton>
            </Stack>
            <Typography variant="body2" fontWeight={600}>
                {currentMonth
                    .locale('pt-br')
                    .format('MMMM')
                    .toLocaleUpperCase()}
            </Typography>
            <Stack spacing={1} direction="row">
                <IconButton onClick={selectNextMonth} color="primary">
                    <ChevronRight />
                </IconButton>
            </Stack>
        </CustomCalendarHeaderRoot>
    )
}

type CustomDatePickerProps = {
    format?: string
    minDate?: dayjs.Dayjs
    maxDate?: dayjs.Dayjs
    defaultValue?: dayjs.Dayjs
    value?: dayjs.Dayjs
    label: string
    useTime?: boolean
    onChange: (date: Dayjs) => void
}

export default function CustomDatePicker({
    format = 'DD/MM/YYYY',
    minDate,
    maxDate,
    defaultValue,
    value,
    label,
    useTime,
    onChange,
}: CustomDatePickerProps) {
    const handleChange = useCallback(
        (dayJs: Dayjs | null) => {
            if (!dayJs) return
            onChange(dayJs as Dayjs)
        },
        [onChange]
    )

    const sharedProps = useMemo(
        () => ({
            minDate,
            maxDate,
            slotProps: {
                day: {
                    sx: {
                        color: COLORS.brownishGrey,
                        fontWeight: 700,
                    },
                },
                layout: {
                    sx: {
                        '& .MuiDayCalendar-weekDayLabel': {
                            color: COLORS.primaryOrange,
                        },
                        backgroundColor: COLORS.borderGray,
                    },
                },
            },
            slots: { calendarHeader: CustomCalendarHeader },
            format,
            label,
            defaultValue,
            value,
        }),
        [minDate, maxDate, format, label, defaultValue, value]
    )

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            {useTime ? (
                <DateTimePicker
                    {...sharedProps}
                    ampm={false}
                    views={['year', 'month', 'day', 'hours', 'minutes']}
                    minutesStep={1}
                    onChange={handleChange}
                />
            ) : (
                <DatePicker {...sharedProps} onChange={handleChange} />
            )}
        </LocalizationProvider>
    )
}

const CustomCalendarHeaderRoot = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    padding: '8px 16px',
    alignItems: 'center',
})

import { useEffect, useState } from 'react'

import debounce from '../commons/utils/Debounce'
import { SnackbarConfigTypes } from '../commons/utils/Types'
import { CommercialRules } from '../models/CommercialRules'
import * as CommercialRulesAPI from './CommercialRulesAPI'
import { deleteCommercialRules } from './CommercialRulesAPI'
import CommercialRulesView from './CommercialRulesView'
import { CommercialRulesHeadersOptions } from './commons/Utils/TableHeaders'

export default function CommercialRulesContainer() {
    const [rules, setRules] = useState<CommercialRules[]>([])
    const [paginatedRules, setPaginatedRules] = useState<CommercialRules[]>([])
    const [rulesQuantity, setRulesQuantity] = useState<number>(0)
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [idDeleted, setIdDeleted] = useState<string>('')
    const [page, setPage] = useState<number>(1)
    const [filter, setFilter] = useState<string>('')
    const [snackbarConfig, setSnackBarConfig] = useState<SnackbarConfigTypes>({
        message: '',
        isVisible: false,
        status: 'success',
    })
    const [orderByAsc, setOrderByAsc] = useState<boolean>(true)
    const [orderByType, setOrderByType] = useState<string>('')

    const getFileNameByPath = (path: string) => {
        return path.split('/').pop()
    }

    const paginate = (commercialRules: CommercialRules[]) => {
        const size = 10
        const startIndex = (page - 1) * size
        const endIndex = startIndex + size

        return commercialRules.slice(startIndex, endIndex)
    }

    const handleFilter = (commercialRules: CommercialRules[]) => {
        return commercialRules.filter(({ title, type, path }) => {
            return `${title}${type!.name}${getFileNameByPath(path!)}`
                .toUpperCase()
                .includes(filter.toUpperCase())
        })
    }

    const handlePagination = (
        _event: React.ChangeEvent<unknown>,
        value: number
    ) => {
        setPage(value)
    }

    const handleChangeFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
        debounce(() => {
            setFilter(event.target.value)
            setPage(1)
        })
    }

    const handleCloseSnackbar = () => {
        setSnackBarConfig((prev) => ({ ...prev, isVisible: false }))
    }

    const applyOrderBy = (
        data: CommercialRules[],
        type: string,
        asc: boolean
    ): CommercialRules[] => {
        return [...data].sort((a, b) => {
            const RULE_A =
                type === 'type'
                    ? a.type!.name
                    : a[type as CommercialRulesHeadersOptions]!.toString()
            const RULE_B =
                type === 'type'
                    ? b.type!.name
                    : b[type as CommercialRulesHeadersOptions]!.toString()
            return asc
                ? RULE_A.localeCompare(RULE_B)
                : RULE_B.localeCompare(RULE_A)
        })
    }

    const handleOrderByHeader = (type: string) => {
        const orderedRules = applyOrderBy(rules, type, orderByAsc)
        setRules(orderedRules)
        setOrderByAsc((prev) => !prev)
        setOrderByType(type)
    }

    const handleDownloadFile = async (path: string) => {
        try {
            const response = await fetch(path, {
                method: 'GET',
            })

            const blob = await response.blob()
            const url = window.URL.createObjectURL(blob)
            const downloadedFile = document.createElement('a')
            downloadedFile.href = url
            downloadedFile.download = `${getFileNameByPath(path)}`
            document.body.appendChild(downloadedFile)
            downloadedFile.click()
            document.body.removeChild(downloadedFile)
            window.URL.revokeObjectURL(url)
        } catch (error) {
            console.error('Erro ao baixar a imagem:', error)
            setSnackBarConfig({
                message: 'Erro ao baixar imagem, tente novamente mais tarde!',
                isVisible: true,
                status: 'error',
            })
        }
    }

    async function fetchRules(): Promise<void> {
        try {
            setIsLoading(true)
            const response = await CommercialRulesAPI.getCommercialRules()

            if (response.data) {
                const filteredData = handleFilter(response.data)
                setRules(response.data)
                setPaginatedRules(paginate(filteredData))
                setRulesQuantity(filteredData.length)
            }
        } catch (err) {
            console.error(err)
            setSnackBarConfig({
                message: 'Erro ao buscar regras comerciais',
                isVisible: true,
                status: 'error',
            })
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchRules()
    }, [])

    useEffect(() => {
        if (rules.length > 0) {
            const filteredData = handleFilter(rules)
            setPaginatedRules(paginate(filteredData))
            setRulesQuantity(filteredData.length)
        }
    }, [page, filter, rules])

    const handleDelete = async (id: string): Promise<void> => {
        setIdDeleted('')
        try {
            setIsLoading(true)
            const result = await deleteCommercialRules(id)

            if (result.message === 'CommercialRule deletada com sucesso.') {
                setSnackBarConfig({
                    message: 'Comunicado excluído com sucesso.',
                    isVisible: true,
                    status: 'success',
                })

                const updatedRules = rules.filter((rule) => rule.id !== id)
                const orderedRules =
                    orderByType !== ''
                        ? applyOrderBy(updatedRules, orderByType, !orderByAsc)
                        : updatedRules

                setRules(orderedRules)
                const filteredData = handleFilter(orderedRules)
                setPaginatedRules(paginate(filteredData))
                setRulesQuantity(filteredData.length)
            }
        } catch (err) {
            console.error(err)
            setSnackBarConfig({
                message:
                    'Ocorreu um erro, por favor tente novamente mais tarde!',
                isVisible: true,
                status: 'error',
            })
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <CommercialRulesView
            handlePagination={handlePagination}
            page={page}
            idDeleted={idDeleted}
            isLoading={isLoading}
            handleFilter={handleChangeFilter}
            rows={paginatedRules}
            totalRecords={rulesQuantity}
            setIdDeleted={setIdDeleted}
            snackbarConfig={snackbarConfig}
            handleCloseSnackbar={handleCloseSnackbar}
            handleOrderByHeader={handleOrderByHeader}
            handleDownloadFile={handleDownloadFile}
            handleDelete={handleDelete}
        />
    )
}

import * as React from 'react'
import { JSX } from 'react/jsx-runtime'

const RedCirclesIcon = (
    props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
) => (
    <svg
        width={166.64715447154475}
        height={68.544}
        viewBox="4.011825572801183 0 166.64715447154475 68.544"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g opacity={0.15}>
            <mask
                id="l5yt8x2f3a"
                style={{
                    maskType: 'alpha',
                }}
                maskUnits="userSpaceOnUse"
                x={0}
                y={0}
                width={184}
                height={68}
            >
                <path
                    d="M149.877 0c-16.967 0-30.975 12.657-33.309 29.14C114.21 12.657 100.202 0 83.238 0c-9.802 0-18.61 4.265-24.78 11.003C52.308 4.243 43.502 0 33.702 0 15.094 0 0 15.222 0 34.012 0 52.778 15.094 68 33.702 68c9.8 0 18.607-4.243 24.756-10.98C64.628 63.756 73.436 68 83.238 68c16.964 0 30.972-12.659 33.33-29.116C118.902 55.341 132.91 68 149.877 68c18.63 0 33.723-15.222 33.723-33.988C183.6 15.222 168.507 0 149.877 0z"
                    fill="#F08B1F"
                />
            </mask>
            <g mask="url(#l5yt8x2f3a)">
                <path
                    fill="#F08B1F"
                    d="M-1.658-18.244h174.146v92.878H-1.658z"
                />
            </g>
        </g>
    </svg>
)

export default RedCirclesIcon

import { useEffect, useState } from 'react'

import { SnackbarConfigTypes } from '../../../commons/utils/Types'
import { UnavailabilityConfig } from '../../../models/UnavailabilityConfig'
import {
    getUnavailabilityConfig,
    setUnavailabilityConfig,
} from '../../AppPermissionsAPI'
import { HandleDefaultConfig } from '../billingDateConfig/BillingDateConfigContainer'
import UnavailabilityConfigView from './UnavailabilityConfigView'

export default function UnavailabilityConfigContainer() {
    const [snackbarConfig, setSnackBarConfig] = useState<SnackbarConfigTypes>({
        message: '',
        isVisible: false,
        status: 'success',
    })
    const [isLoadingConfig, setIsLoadingConfig] = useState<boolean>(true)
    const [isSavingConfig, setIsSavingConfig] = useState<boolean>(false)
    const [unavailabilityPeriod, setUnavailabilityPeriod] =
        useState<UnavailabilityConfig>({ period: { start: 0, end: 0 } })

    useEffect(() => {
        loadConfig()
    }, [])

    async function loadConfig() {
        try {
            const { data } = await getUnavailabilityConfig()
            if (data) setUnavailabilityPeriod(data)
        } catch (error) {
            setSnackBarConfig((prev) => ({
                ...prev,
                isVisible: true,
                message: `Erro ao buscar configuração de datas, por favor tente mais tarde!`,
                status: 'error',
            }))
        } finally {
            setIsLoadingConfig(false)
        }
    }

    const handleSaveConfig = async () => {
        try {
            setIsSavingConfig(true)
            await setUnavailabilityConfig(unavailabilityPeriod)
            setSnackBarConfig((prev) => ({
                ...prev,
                isVisible: true,
                message: `Configuração salva com sucesso.`,
                status: 'success',
            }))
        } catch (error) {
            setSnackBarConfig((prev) => ({
                ...prev,
                isVisible: true,
                message: `Ocorreu um erro ao tentar salvar a configuração.`,
                status: 'error',
            }))
        } finally {
            setIsSavingConfig(false)
        }
    }

    const handleCloseSnackbar = () => {
        setSnackBarConfig((prev) => ({ ...prev, isVisible: false }))
    }

    const handleDefaultConfig = ({ period, value }: HandleDefaultConfig) => {
        setUnavailabilityPeriod((prevConfig: UnavailabilityConfig) => ({
            ...prevConfig,
            period: {
                ...prevConfig.period,
                [period]: value.valueOf(),
            },
        }))
    }

    return (
        <UnavailabilityConfigView
            unavailabilityConfig={unavailabilityPeriod}
            handleSaveConfig={handleSaveConfig}
            handleCloseSnackbar={handleCloseSnackbar}
            snackbarConfig={snackbarConfig}
            handleDefaultConfig={handleDefaultConfig}
            isLoadingConfig={isLoadingConfig}
            isSavingConfig={isSavingConfig}
        />
    )
}

import { AttachFile as AttachFileIcon } from '@mui/icons-material'
import styled from 'styled-components'

import { COLORS } from '../Colors'

type DownloadFileProps = {
    name: string
    onPress: () => void
}

export default function DownloadFile({ name, onPress }: DownloadFileProps) {
    return (
        <Button data-testid="download-file-button" onClick={onPress}>
            <AttachFileIcon
                sx={{ color: COLORS.primaryGray, marginRight: '10px' }}
            />
            {name}
        </Button>
    )
}

const Button = styled.button`
    padding: 5px 15px 3px 10px;
    border-radius: 32px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.25);
    background-color: ${COLORS.veryLightPink};
    border: none;
    display: flex;
    align-items: center;
    color: ${COLORS.primaryGray};
    cursor: pointer;
`

import { Button } from '@mui/material'
import { styled as materialStyled } from '@mui/material/styles'
import styled from 'styled-components'

import { COLORS } from '../Colors'
import CustomModalComponent from './CustomModalComponent'

export type ConfirmationModalComponentProps = {
    isVisible: boolean
    message?: JSX.Element | React.ReactNode
    textActionButton: string
    title: string
    onAction: () => Promise<void> | void
    onClose: () => void
}

function ConfirmationModalComponent({
    isVisible,
    message,
    textActionButton,
    title,
    onAction,
    onClose,
}: ConfirmationModalComponentProps): JSX.Element {
    return (
        <CustomModalComponent
            title={title}
            isVisible={isVisible}
            onClose={onClose}
            modalWidth="504px"
        >
            <ModalContentContainer>
                <ModalSubtitleContainer>
                    {message && (
                        <SimpleText data-testid="modal-message">
                            {message}
                        </SimpleText>
                    )}
                </ModalSubtitleContainer>
                <ModalButtonContainer>
                    <CancelButton
                        data-testid="CancelButton"
                        variant="outlined"
                        onClick={onClose}
                    >
                        Cancelar
                    </CancelButton>
                    <ActionButton
                        data-testid="ActionButton"
                        variant="outlined"
                        onClick={onAction}
                    >
                        {textActionButton}
                    </ActionButton>
                </ModalButtonContainer>
            </ModalContentContainer>
        </CustomModalComponent>
    )
}

const ModalContentContainer = styled.div`
    padding-top: 14px;
    display: flex;
    flex-direction: column;
    align-items: center;
`
const ModalSubtitleContainer = styled.div`
    width: 100%;
    display: flex;
`
const ModalButtonContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 24px 0 0 0;
`

const SimpleText = styled.span`
    font-family: Roboto;
    font-size: 14px;
    line-height: 24px;
    color: ${COLORS.thirdGray};
`

const CancelButton = materialStyled(Button)({
    fontSize: 14,
    fontWeight: 'normal',
    textTransform: 'none',
    lineHeight: 1.71,
    fontFamily: 'Roboto',
    backgroundColor: COLORS.primaryWhite,
    border: `1px solid ${COLORS.inactive}`,
    color: COLORS.inactive,
    borderRadius: '24px',
    padding: '4px 25px 4px 26px',
    marginRight: '10px',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
})

const ActionButton = materialStyled(Button)({
    fontSize: 14,
    fontWeight: 'normal',
    textTransform: 'none',
    lineHeight: 1.71,
    backgroundColor: COLORS.inactive,
    borderColor: COLORS.primaryWhite,
    borderRadius: '24px',
    color: COLORS.primaryWhite,
    fontFamily: 'Roboto',
    padding: '4px 20px 4px 31.5px',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': {
        backgroundColor: COLORS.inactive,
        borderColor: COLORS.primaryWhite,
        boxShadow: 'none',
    },
    '&:disabled': {
        backgroundColor: COLORS.disabledButton,
        borderColor: COLORS.disabledButton,
        color: COLORS.primaryWhite,
        boxShadow: 'none',
    },
})

export default ConfirmationModalComponent

import { CommercialRulesListHeaders } from '../../../millManegement/Utils'

export const commercialRulesHeaders: CommercialRulesListHeaders[] = [
    { label: 'Categoria', value: 'type', hasSwapVertIcon: true },
    {
        label: 'Titulo do comunicado',
        value: 'title',
        hasSwapVertIcon: true,
    },
    { label: 'Disponibilidade', value: 'active', hasSwapVertIcon: true },
    { label: 'Documento', value: 'path', hasSwapVertIcon: true },
    { label: 'Opções', value: 'icons', hasSwapVertIcon: false },
]

export type CommercialRulesHeadersOptions = 'type' | 'path' | 'active' | 'title'

import styled from 'styled-components'

import { COLORS } from '../commons/Colors'
import MainHeaderComponent from '../commons/components/MainHeaderComponent'
import { useUserContext } from '../context/UserContext'
import MainDsmContent from './components/MainDsmContent'

function HomeScreen(): JSX.Element {
    const { user } = useUserContext()

    return (
        <PageContainer>
            <MainHeaderComponent />
            <PageTitle>
                {user?.isDsm && user?.isUserGroupsSTC
                    ? 'Time DSM-Firmenich'
                    : 'Portal Administrativo'}
            </PageTitle>
            {user?.isDsm && user?.isUserGroupsSTC && <MainDsmContent />}
        </PageContainer>
    )
}

const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
`

const PageTitle = styled.h1`
    color: ${COLORS.primaryOrange};
    font-size: 18px;
    font-family: Roboto;
    margin: auto;
    font-weight: normal;
    margin-top: 25px;
`

export default HomeScreen

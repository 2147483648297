export const COLORS = {
    // Generics
    primaryOrange: '#e57509',
    primaryWhite: '#ffffff',
    secondaryWhite: '#fbfbfb',
    thirdWhite: '#f6f6f6',
    primaryBlue: '#4b74ff',
    primaryGray: '#666666',
    secondaryGray: '#8e8e93',
    thirdGray: '#6c6e6f',
    lightOrange: 'rgba(229, 115, 9, 0.5)',
    lightishOrange: 'rgba(241, 141, 58, 0.1)',
    lightBlueGrey: '#c6cbd4',
    lightBlueGreyTwo: '#c7c7cc',
    veryLightPink: '#f1f1f1',
    black: '#333333',
    brownishGrey: '#666666',
    primaryGreen: '#4da32f',
    secondaryGreen: '#55b234',
    thirdGreen: '#b2bf00',

    // Specifics
    urlBlue: '#0053a0',
    mainHeaderText: '#333333',
    portalRedTag: '#e64858',
    borderGray: '#eceeee',
    lightBorderGray: '#e0e0e0',

    // Disabled
    disabledButton: '#c7c7cc',
    disabledText: '#cacacf',

    // Status
    warning: '#fab131',
    error: '#e74c3c',
    success: '#78c47b',
    snackBarSuccess: '#78c47b',
    inactive: '#f44336',
    notEnabled: '#e5e5e5',
}

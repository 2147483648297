/* eslint-disable no-return-await */
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import LabelIcon from '@mui/icons-material/Label'
import Skeleton from '@mui/material/Skeleton'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { COLORS } from '../commons/Colors'
import ConfirmationModalComponent from '../commons/components/ConfirmationModalComponent'
import DownloadFile from '../commons/components/DownloadFile'
import MainHeaderComponent from '../commons/components/MainHeaderComponent'
import RoundButtonComponent from '../commons/components/RoundButtonComponent'
import SnackBarComponent from '../commons/components/SnackBarComponent'
import TableListWithPaginationComponent from '../commons/components/TableListWithPaginationComponent'
import { ROUTES } from '../commons/utils/AppRoutes'
import { SnackbarConfigTypes } from '../commons/utils/Types'
import { CommercialRules } from '../models/CommercialRules'
import GlobalThemeProvider from '../style/GlobalTheme'
import { commercialRulesHeaders } from './commons/Utils/TableHeaders'

export type CommercialRulesViewProps = {
    page: number
    totalRecords: number
    rows: CommercialRules[]
    idDeleted: string
    isLoading: boolean
    setIdDeleted: (id: string) => void
    snackbarConfig: SnackbarConfigTypes
    handleCloseSnackbar: () => void
    handlePagination: (_event: React.ChangeEvent<unknown>, page: number) => void
    handleFilter: (_event: React.ChangeEvent<HTMLInputElement>) => void
    handleOrderByHeader: (type: string) => void
    handleDownloadFile: (path: string) => void
    handleDelete: (id: string) => Promise<void>
}

export default function CommercialRulesView({
    page,
    totalRecords,
    rows,
    idDeleted,
    isLoading,
    snackbarConfig,
    setIdDeleted,
    handleCloseSnackbar,
    handlePagination,
    handleFilter,
    handleOrderByHeader,
    handleDownloadFile,
    handleDelete,
}: CommercialRulesViewProps): JSX.Element {
    const getFileNameByPath = (path: string) => {
        return path.split('/').pop()
    }

    const getLabel = (name: string, color: string) => {
        return (
            <LabelContainer>
                <LabelIcon sx={{ color }} />
                <CategoryName>{name}</CategoryName>
            </LabelContainer>
        )
    }

    const createRow = (rules: CommercialRules[]) => {
        return rules.map((rule) => {
            const { type, title, active, path, id } = rule
            return {
                type: getLabel(type!.name, type!.color!),
                title,
                active: active ? 'Ativo' : 'Inativo',
                path: path ? (
                    <DownloadFile
                        name={getFileNameByPath(path) as string}
                        onPress={() => handleDownloadFile(path)}
                    />
                ) : (
                    <Emptytext> - </Emptytext>
                ),
                icons: (
                    <ButtonsContainer key={id}>
                        <StyledLink
                            to={`/regras/comerciais/${id}`}
                            data-testid={`edit-commercial-rules-${id}`}
                        >
                            <EditIcon sx={{ color: COLORS.primaryGray }} />
                        </StyledLink>
                        <Button
                            data-testid={`delete-commercial-rules-${id}`}
                            onClick={() => setIdDeleted(id)}
                        >
                            <DeleteIcon sx={{ color: COLORS.primaryGray }} />
                        </Button>
                    </ButtonsContainer>
                ),
            }
        })
    }

    return (
        <GlobalThemeProvider>
            <PageContainer>
                <MainHeaderComponent />
                <PageTitle>Regras comerciais - Time DSM-Firmenich</PageTitle>
                <MainContainer>
                    <SubTitle>GESTÃO DE INFORMATIVOS</SubTitle>
                    <RowContainer>
                        <FilterContainer>
                            <LabelField htmlFor="search-input-component">
                                Filtro
                            </LabelField>
                            <InputField
                                placeholder="Pesquisar comunicado"
                                onChange={handleFilter}
                            />
                        </FilterContainer>
                        <ButtonConainer>
                            <Link to={ROUTES.COMMERCIALRULESNEW}>
                                <RoundButtonComponent
                                    text="Novo comunicado"
                                    size="large"
                                />
                            </Link>
                        </ButtonConainer>
                    </RowContainer>
                    {isLoading ? (
                        <Skeleton
                            data-testid="loading-skeleton"
                            variant="rectangular"
                            width="100%"
                            height={500}
                            animation="wave"
                        />
                    ) : (
                        <TableListWithPaginationComponent
                            totalRecords={totalRecords}
                            onChange={handlePagination}
                            currentPage={page}
                            rows={createRow(rows)}
                            headers={commercialRulesHeaders}
                            centerAlign={false}
                            onChangeOrderBy={handleOrderByHeader}
                        />
                    )}
                </MainContainer>
            </PageContainer>

            <ConfirmationModalComponent
                isVisible={idDeleted !== ''}
                message={
                    <>
                        Tem certeza que deseja apagar o comunicado selecionado?
                        O <br />
                        informativo será excluído do sistema e, caso esteja
                        ativo, será apagado <br />
                        do aplicativo.
                    </>
                }
                textActionButton="Sim, excluir"
                title="Excluir comunicado"
                onAction={async () => await handleDelete(idDeleted)}
                onClose={() => setIdDeleted('')}
            />

            <SnackBarComponent
                handleClose={handleCloseSnackbar}
                isVisible={snackbarConfig.isVisible}
                message={snackbarConfig.message}
                severity={snackbarConfig.status}
            />
        </GlobalThemeProvider>
    )
}

const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: ${COLORS.secondaryWhite};
`

const PageTitle = styled.h1`
    color: ${COLORS.primaryOrange};
    font-size: 18px;
    font-family: Roboto;
    margin: auto;
    font-weight: normal;
    margin-top: 25px;
`

const MainContainer = styled.div`
    background-color: ${COLORS.primaryWhite};
    border: solid 1px ${COLORS.borderGray};
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    margin: 30px;
    padding: 25px 25px 50px 25px;
`

const SubTitle = styled.h1`
    align: start;
    color: ${COLORS.primaryGray};
    font-size: 12px;
    font-family: Roboto;
    font-weight: bold;
    text-align: start;
    margin-bottom: 70px;
`

const FilterContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 30%;
`

const LabelField = styled.label`
    color: ${COLORS.primaryGray};
    font-family: Roboto;
    font-size: 12px;
    font-weight: bold;
    line-height: 2;
    margin-bottom: 5px;
    text-transform: uppercase;
`

const InputField = styled.input`
    border: 1px solid ${COLORS.disabledButton};
    border-radius: 4px;
    font-family: Roboto;
    font-size: 14px;
    height: 30px;
    line-height: 1.43;
    padding: 8px;

    :focus {
        outline-color: ${COLORS.primaryOrange};
    }
`

const RowContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
`

const ButtonConainer = styled.div`
    align-self: end;
    max-height: 40px;
`

const ButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`

const Button = styled.button`
    border: none;
    background: transparent;
    cursor: pointer;
    transition: transform 0.1s ease, opacity 0.1s ease;

    :hover {
        opacity: 0.8;
    }

    :active {
        transform: scale(0.95);
        opacity: 0.6;
    }
`

const LabelContainer = styled.div`
    display: flex;
    justify-content: start;
    align-items: center;
    height: 40px;
`

const CategoryName = styled.p`
    margin-left: 10px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
`

const Emptytext = styled.p`
    text-align: left;
`

const StyledLink = styled(Link)`
    border: none;
    background: transparent;
    cursor: pointer;
    transition: transform 0.1s ease, opacity 0.1s ease;
    display: flex;
    align-items: center;

    :hover {
        opacity: 0.8;
    }

    :active {
        transform: scale(0.95);
        opacity: 0.6;
    }
`

import AttachFileIcon from '@mui/icons-material/AttachFile'
import CancelIcon from '@mui/icons-material/Cancel'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import { Button } from '@mui/material'
import { Accept, FileWithPath, useDropzone } from 'react-dropzone'
import styled from 'styled-components'

import GlobalThemeProvider from '../../style/GlobalTheme'
import { COLORS } from '../Colors'

type FileUploaderComponentProps = {
    maxFiles?: number
    accept?: Accept
    onFilesSelected: (file: FileWithPath[]) => void
    fileName?: string
    label: string
}

export default function FileUploaderComponent({
    onFilesSelected,
    fileName,
    maxFiles = 1,
    accept,
    label,
}: FileUploaderComponentProps) {
    const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
        onDrop: onFilesSelected,
        maxFiles,
        accept: accept || {
            'image/*': ['.png', '.jpg'],
            'application/xml': ['.xls', '.xlsx', '.csv'],
            'application/pdf': ['.pdf'],
        },
        maxSize: 10 * 1024 * 1024,
    })

    return (
        <GlobalThemeProvider>
            <InputLabel>{label}</InputLabel>

            <DropzoneContainer {...getRootProps()}>
                <input data-testid="dropzone-input" {...getInputProps()} />
                {acceptedFiles.length === 0 && !fileName ? (
                    <DropzoneContentContainer>
                        <CloudUploadIcon color="primary" />
                        <GrayText>Araste e solte o arquivo aqui ou</GrayText>
                        <OrangeText>Selecione o arquivo...</OrangeText>
                    </DropzoneContentContainer>
                ) : (
                    <DropzoneFullfiledContainer>
                        <Button
                            startIcon={<AttachFileIcon />}
                            endIcon={<CancelIcon />}
                            style={{
                                backgroundColor: COLORS.borderGray,
                                color: COLORS.primaryGray,
                                fontFamily: 'Roboto',
                                fontSize: '12px',
                            }}
                            variant="contained"
                        >
                            {acceptedFiles[0]?.name || fileName}
                        </Button>
                    </DropzoneFullfiledContainer>
                )}
            </DropzoneContainer>
        </GlobalThemeProvider>
    )
}

const DropzoneContainer = styled.div`
    disply: flex;
    flex-direction: column;
    align-content: center;
    cursor: pointer;
    border: 2px solid ${COLORS.borderGray};
    border-radius: 3px;
    height: 110px;
`
const DropzoneContentContainer = styled.div`
    padding: 15px;
    text-align: center;
    font-size: 12px;
    letter-spacing: 0.09px;
`

const DropzoneFullfiledContainer = styled.div`
    display: flex;
    padding: 15px;
    content-align: left;
    font-size: 12px;
    letter-spacing: 0.09px;
`

const OrangeText = styled.p`
    color: ${COLORS.primaryOrange};
`
const GrayText = styled.p`
    color: ${COLORS.primaryGray};
`
const InputLabel = styled.span`
    flex-grow: 0;
    font-family: Roboto;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 3;
    letter-spacing: 0.09px;
    text-align: left;
    color: ${COLORS.primaryGray};
    text-transform: uppercase;
`

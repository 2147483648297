import { getAPIKey, getBaseURL, getOrigin, getUserToken } from '../environment'
import {
    CommercialRules,
    CommercialRulesForm,
    RuleType,
} from '../models/CommercialRules'

const baseURL = getBaseURL()
const APIKey = getAPIKey()
const origin = getOrigin()
const token = getUserToken()

export const getCommercialRules = async (): Promise<{
    data: CommercialRules[]
}> => {
    return fetch(`${baseURL}/news/commercialRules`, {
        method: 'GET',
        headers: {
            'x-api-key': APIKey,
            'x-origin': origin,
            Authorization: `Bearer ${token}`,
        },
    })
        .then((response: Response) => {
            const responseJson = response.json()
            if (!response.ok) {
                throw new Error(JSON.stringify(responseJson))
            }
            return responseJson
        })
        .catch((err) => {
            console.error('Erro: ', JSON.stringify(err))
            throw err
        })
}
export const getRuleTypes = async (): Promise<{
    data: RuleType[]
}> => {
    return fetch(`${baseURL}/news/commercialRulesTypes`, {
        method: 'GET',
        headers: {
            'x-api-key': APIKey,
            'x-origin': origin,
            Authorization: `Bearer ${token}`,
        },
    })
        .then((response: Response) => {
            const responseJson = response.json()
            if (!response.ok) {
                throw new Error(JSON.stringify(responseJson))
            }
            return responseJson
        })
        .catch((err) => {
            console.error('Erro: ', JSON.stringify(err))
            throw err
        })
}
export const getById = async (
    id: string
): Promise<{
    data: CommercialRules
}> => {
    return fetch(`${baseURL}/news/commercialRules/${id}`, {
        method: 'GET',
        headers: {
            'x-api-key': APIKey,
            'x-origin': origin,
            Authorization: `Bearer ${token}`,
        },
    })
        .then((response: Response) => {
            const responseJson = response.json()
            if (!response.ok) {
                throw new Error(JSON.stringify(responseJson))
            }
            return responseJson
        })
        .catch((err) => {
            console.error('Erro: ', JSON.stringify(err))
            throw err
        })
}

export const create = async (
    comercialRule: CommercialRulesForm
): Promise<void> => {
    const body = mountFormData(comercialRule)

    return fetch(`${baseURL}/news/commercialRules`, {
        method: 'POST',
        headers: {
            'x-api-key': APIKey,
            'x-origin': origin,
            Authorization: `Bearer ${token}`,
        },
        body,
    })
        .then((response: Response) => {
            const responseJson = response.json()
            if (!response.ok) {
                throw new Error(JSON.stringify(responseJson))
            }
            return responseJson
        })
        .catch((err) => {
            console.error('Erro: ', JSON.stringify(err))
            throw err
        })
}
export const update = async (
    id: string,
    comercialRules: Partial<CommercialRulesForm>
): Promise<void> => {
    const body = mountFormData(comercialRules)
    return fetch(`${baseURL}/news/commercialRules/${id}`, {
        method: 'PATCH',
        headers: {
            'x-api-key': APIKey,
            'x-origin': origin,
            Authorization: `Bearer ${token}`,
        },
        body,
    })
        .then((response: Response) => {
            const responseJson = response.json()
            if (!response.ok) {
                throw new Error(JSON.stringify(responseJson))
            }
            return responseJson
        })
        .catch((err) => {
            console.error('Erro: ', JSON.stringify(err))
            throw err
        })
}

function mountFormData(obj: object) {
    const formData = new FormData()
    Object.entries(obj).forEach(([key, value]) => {
        formData.append(key, value)
    })
    return formData
}

export const deleteCommercialRules = async (
    id: string
): Promise<{ message: string }> => {
    return fetch(`${baseURL}/news/commercialRules/${id}`, {
        method: 'DELETE',
        headers: {
            'x-api-key': APIKey,
            'x-origin': origin,
            Authorization: `Bearer ${token}`,
        },
    })
        .then((response: Response) => {
            const responseJson = response.json()
            if (!response.ok) {
                throw new Error(JSON.stringify(responseJson))
            }
            return responseJson
        })
        .catch((err) => {
            console.error('Erro: ', JSON.stringify(err))
            throw err
        })
}

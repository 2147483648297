import { SwapVert as SwapVertIcon } from '@mui/icons-material'
import { styled as styledMui } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import styled from 'styled-components'

import { COLORS } from '../Colors'
import PaginationComponent from './PaginationComponent'

type TableListWithPaginationComponentProps = {
    totalRecords: number
    currentPage: number
    onChange: (_event: React.ChangeEvent<unknown>, page: number) => void
    rows: {
        [field: string]: string | number | undefined | null | JSX.Element
    }[]
    headers: { label: string; value: string; hasSwapVertIcon?: boolean }[]
    maxRowsInList?: number
    onChangeOrderBy?: (type: string) => void
    centerAlign?: boolean
}

function TableListWithPaginationComponent({
    totalRecords,
    onChange,
    currentPage,
    rows,
    headers,
    maxRowsInList = 10,
    onChangeOrderBy,
    centerAlign = true,
}: TableListWithPaginationComponentProps): JSX.Element {
    function countPages() {
        return totalRecords / maxRowsInList
    }
    return (
        <TableContainer>
            <Table
                sx={{
                    minWidth: 700,
                    border: 1,
                    borderColor: COLORS.borderGray,
                }}
            >
                <TableHead>
                    <TableRow>
                        {headers.map((header, i) => (
                            <StyledTableCell
                                align="center"
                                data-testid={`header-${i}`}
                                key={`header-${header.label}`}
                            >
                                <CellContainer centerAlign={centerAlign}>
                                    {header.label}
                                    <OrderByContainer
                                        data-testid={`order-by-${header.value}`}
                                        onClick={() =>
                                            onChangeOrderBy?.(header.value)
                                        }
                                    >
                                        {header.hasSwapVertIcon && (
                                            <SwapVertIcon
                                                sx={{
                                                    color: COLORS.black,
                                                    fontSize: '20px',
                                                }}
                                            />
                                        )}
                                    </OrderByContainer>
                                </CellContainer>
                            </StyledTableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row, i) => (
                        <TableRow
                            key={`${row.name?.toString()}-${i.toString()}`}
                        >
                            {Object.keys(row).map((_key, index) => {
                                return (
                                    <TableCell
                                        component="th"
                                        scope="row"
                                        align={centerAlign ? 'center' : 'left'}
                                    >
                                        {row[headers[index].value]}
                                    </TableCell>
                                )
                            })}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <PaginationContainer>
                <PaginationComponent
                    numberOfPages={countPages()}
                    currentPage={currentPage}
                    onChange={onChange}
                />
            </PaginationContainer>
        </TableContainer>
    )
}

export default TableListWithPaginationComponent

const PaginationContainer = styled.div`
    padding: 7px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid ${COLORS.borderGray};
    border-top: 0px solid ${COLORS.borderGray};
`

const StyledTableCell = styledMui(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: COLORS.borderGray,
        color: COLORS.primaryGray,
        fontSize: 14,
        fontWeight: 700,
        border: 0,
    },
}))

const CellContainer = styled.div<{ centerAlign: boolean }>`
    display: flex;
    justify-content: ${({ centerAlign }) =>
        centerAlign ? 'center' : 'flex-start'};
    align-items: center;
`

const OrderByContainer = styled.div`
    height: 20px;
    cursor: pointer;
`

import { useState } from 'react'
import styled from 'styled-components'

import { COLORS } from '../commons/Colors'
import MainHeaderComponent from '../commons/components/MainHeaderComponent'
import TabSelectorComponent from '../commons/components/TabSelectorComponent'
import CustomerProfileScreen from './customerProfile/CustomerProfileScreen'
import DateManagementScreen from './dateManagement/DateManagementScreen'

export default function AppPermissionsView() {
    const billingDateConfigTab = {
        label: 'Customizações de datas e prazos',
        renderTabComponent: () => <DateManagementScreen />,
    }

    const customerProfileTab = {
        label: 'Customizações por perfil de cliente',
        renderTabComponent: () => <CustomerProfileScreen />,
    }

    const pageTabs = [billingDateConfigTab, customerProfileTab]

    const [selectedTab, setSelectedTab] = useState(billingDateConfigTab)

    return (
        <PageContainer>
            <MainHeaderComponent />
            <PageTitle>Permissionamentos - Time DSM-Firmenich</PageTitle>
            <TabSelectorComponent
                tabNames={pageTabs}
                getSelectedTab={setSelectedTab}
                isMainTab
            />
            {selectedTab.renderTabComponent()}
            {selectedTab.label === customerProfileTab.label && (
                <WarningText>
                    *Salve as informações após as alterações realizadas. O não
                    salvamento implicará na perda das mesmas.
                </WarningText>
            )}
        </PageContainer>
    )
}

const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: ${COLORS.secondaryWhite};
`

const PageTitle = styled.h1`
    color: ${COLORS.primaryOrange};
    font-size: 18px;
    font-family: Roboto;
    margin: auto;
    font-weight: normal;
    margin-top: 25px;
`

const WarningText = styled.span`
    color: ${COLORS.primaryGray};
    font-size: 12px;
    margin: 30px 0px 30px 45px;
`

import PersonOutline from '@mui/icons-material/PersonOutline'
import React from 'react'
import styled from 'styled-components'

import { useUserContext } from '../../context/UserContext'
import { COLORS } from '../Colors'

export const UserProfileComponent = () => {
    const { user } = useUserContext()
    return (
        <Container>
            <PersonOutline fontSize="large" />
            <UserLogin>{user?.login}</UserLogin>
        </Container>
    )
}

const Container = styled.div`
    color: ${COLORS.primaryGray};
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    padding-bottom: 20px;
    font-size: 14px;
    border-bottom: 1px solid ${COLORS.lightBlueGreyTwo};
`
const UserLogin = styled.span`
    padding: 25px;
`

export default UserProfileComponent

import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { ROUTES } from '../../commons/utils/AppRoutes'
import { SeverityTypes, SnackbarConfigTypes } from '../../commons/utils/Types'
import {
    CommercialRules,
    CommercialRulesForm,
    RuleType,
} from '../../models/CommercialRules'
import * as API from '../CommercialRulesAPI'
import CommercialRulesFormView from './CommercialRulesFormView'

export default function CommercialRulesFormContainer() {
    const navigate = useNavigate()
    const { id } = useParams<string>()
    const [snackbarConfig, setSnackBarConfig] = useState<SnackbarConfigTypes>({
        message: '',
        isVisible: false,
        status: 'success',
    })
    const [ruleTypes, setRuleTypes] = useState<RuleType[]>([])
    const [commercialRules, setCommercialRules] = useState<CommercialRulesForm>(
        {
            title: '',
            active: true,
            file: undefined,
            typeId: '',
        }
    )
    const [updatedFields, setUpdatedFields] = useState<
        Partial<CommercialRulesForm>
    >({})
    const [isSending, setIsSending] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const fetchCommercialRules = async (id: string) => {
        try {
            setIsLoading(true)
            const response = await API.getById(id)
            setCommercialRules(response.data)
        } catch (error) {
            handleSnackBar('Falha ao carregar informações.', 'error')
        } finally {
            setIsLoading(false)
        }
    }

    const fetchCommercialRuleTypes = async () => {
        try {
            setIsLoading(true)
            const response = await API.getRuleTypes()
            setRuleTypes(response.data)
        } catch (error) {
            handleSnackBar('Falha ao carregar informações.', 'error')
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchCommercialRuleTypes()
        if (id) fetchCommercialRules(id)
    }, [id])

    const handleSave = async (): Promise<void> => {
        try {
            setIsSending(true)
            if (id) await API.update(id, updatedFields)
            else await API.create(commercialRules)
            handleSnackBar('Campanha salva com sucesso!', 'success')
            navigate(ROUTES.COMMERCIALRULES)
        } catch (error) {
            handleSnackBar(
                'Ocorreu um erro, por favor tente novamente mais tarde.',
                'error'
            )
        } finally {
            setIsSending(false)
        }
    }

    const handleFormChange = (toUpdate: Partial<CommercialRules>) => {
        if (id) setUpdatedFields((prev) => ({ ...prev, ...toUpdate }))
        setCommercialRules((prev) => ({ ...prev, ...toUpdate }))
    }
    const handleSnackBar = (message: string, status: SeverityTypes): void => {
        setSnackBarConfig({
            message,
            isVisible: true,
            status,
        })
    }
    const handleCloseSnackbar = (): void => {
        setSnackBarConfig((prev) => ({ ...prev, isVisible: false }))
    }
    const isValidForm = () => {
        const hasFile = commercialRules.file || commercialRules.path
        return Boolean(
            commercialRules.title && commercialRules.typeId && hasFile
        )
    }
    return (
        <CommercialRulesFormView
            handleFormChange={handleFormChange}
            handleCloseSnackbar={handleCloseSnackbar}
            snackbarConfig={snackbarConfig}
            handleSave={handleSave}
            isEdition={Boolean(id)}
            isLoading={isLoading}
            isSending={isSending}
            commercialRule={commercialRules}
            isValidForm={isValidForm}
            ruleTypes={ruleTypes}
        />
    )
}

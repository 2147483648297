import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import RedCirclesIcon from '../../assets/icons/SVG/RedCirclesIcon'
import { COLORS } from '../../commons/Colors'

type DsmTabCardProps = {
    title: string
    path: string
}

export default function DsmTabCard({
    title,
    path,
}: DsmTabCardProps): JSX.Element {
    const navigate = useNavigate()

    return (
        <Container onClick={() => navigate(path)}>
            <Title>{title}</Title>
            <RedCirclesIcon />
        </Container>
    )
}

const Container = styled.div`
    align-items: end;
    border: solid 1px ${COLORS.borderGray};
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    height: 120px;
    justify-content: space-between;
    padding: 10px 0 10px 10px;
    margin: 15px;
    width: 400px;
    box-shadow: 0 4px 8px ${COLORS.lightBorderGray};
`
const Title = styled.span`
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: 0.15px;
    align-self: start;
`

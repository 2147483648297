import AssignmentLateIcon from '@mui/icons-material/AssignmentLate'
import CloseIcon from '@mui/icons-material/Close'
import dayjs from 'dayjs'
import styled from 'styled-components'

import { COLORS } from '../../../../commons/Colors'
import { DateConfig, Informations } from '../utils/types'

export type ModalPreviewProps = {
    informations: Informations
    dateConfig: DateConfig
}

export function ModalPreview({ informations, dateConfig }: ModalPreviewProps) {
    return (
        <MainContainer>
            <Header>
                <Title>Dados para preencher a NFe</Title>
                <CloseIcon
                    sx={{ color: COLORS.black, alignSelf: 'flex-start' }}
                />
            </Header>
            <AssignmentLateIcon
                sx={{
                    color: COLORS.thirdGreen,
                    width: 100,
                    height: 100,
                    alignSelf: 'center',
                    margin: 5,
                }}
            />
            <SimpleText>
                Sistema disponível para geração de Notas Fiscais referentes ao
                período:{' '}
            </SimpleText>
            <SimpleText isBold isBlack isCenteredAlign>
                {dayjs(dateConfig.start).format('DD/MM/YYYY')} à{' '}
                {dayjs(dateConfig.end).format('DD/MM/YYYY')}
            </SimpleText>
            <SimpleText>Dados para preenchimento da nota fiscal:</SimpleText>
            <GeneralDataContainer>
                {informations.dataToFill && (
                    <FillTextContainer>
                        <SimpleText>{informations.dataToFill}</SimpleText>
                    </FillTextContainer>
                )}
                <EmailFieldText>
                    Enviar notas fiscais para o email:{' '}
                </EmailFieldText>
                <EmailFieldText isBold>
                    {informations.emailToSend}
                </EmailFieldText>
                <EmailFieldText>Email em cópia para: </EmailFieldText>
                <EmailFieldText isBold>
                    {informations.emailToCopy}
                </EmailFieldText>
            </GeneralDataContainer>
            <NfeDataContainer>
                <SimpleText>Discriminar no corpo da nota fiscal:</SimpleText>
                <SimpleText isBold isBlack>
                    {informations.bodyFields}
                </SimpleText>
            </NfeDataContainer>
            <AdditionalDataContainer>
                <SimpleText>{informations.additionalInfo}</SimpleText>
            </AdditionalDataContainer>
        </MainContainer>
    )
}

const MainContainer = styled.div`
    align-self: center;
    box-shadow: 0 1px 18px 0 rgba(0, 0, 0, 0.3);
    border: 1px solid transparent;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    min-height: 800px;
    padding: 18px;
    width: 450px;
`
const Header = styled.div`
    align-items: end;
    display: flex;
    height: 35px;
    justify-content: space-between;
`

const Title = styled.div`
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: ${COLORS.black};
`

const SimpleText = styled.div<{
    isBold?: boolean
    isBlack?: boolean
    isCenteredAlign?: boolean
    isNotMargin?: boolean
}>`
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.1px;
    box-sizing: border-box;
    white-space: pre-wrap;
    ${({ isBlack }) =>
        `
        color: ${isBlack ? COLORS.black : COLORS.brownishGrey};
    `}
    ${({ isBold }) =>
        isBold &&
        `
        font-weight: bold;
    `}
    ${({ isCenteredAlign }) =>
        isCenteredAlign &&
        `
        align-self: center;
    `}
    margin: 5px 0;
`

const GeneralDataContainer = styled.div`
    align-self: center;
    border: 1px solid ${COLORS.brownishGrey};
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 10px 0;
    padding: 10px;
    width: 425px;
    box-sixing: border-box;
`

const EmailFieldText = styled(SimpleText)`
    margin: 0;
    font-style: italic;
`

const NfeDataContainer = styled.div``

const AdditionalDataContainer = styled.div`
    margin-top: 15px;
`

const FillTextContainer = styled.div`
    margin-bottom: 20px;
`

import { Check as CheckIcon } from '@mui/icons-material'
import Button from '@mui/material/Button'
import Skeleton from '@mui/material/Skeleton'
import { styled as materialStyled } from '@mui/material/styles'
import dayjs from 'dayjs'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { COLORS } from '../../../commons/Colors'
import CustomDatePicker from '../../../commons/components/CustomDatePicker'
import LoadingComponent from '../../../commons/components/LoadingComponent'
import SnackBarComponent from '../../../commons/components/SnackBarComponent'
import Tooltip from '../../../commons/components/Tooltip'
import { SnackbarConfigTypes } from '../../../commons/utils/Types'
import { UnavailabilityConfig } from '../../../models/UnavailabilityConfig'
import GlobalThemeProvider from '../../../style/GlobalTheme'
import { HandleDefaultConfig } from '../billingDateConfig/BillingDateConfigContainer'

type UnavailabilityConfigViewProps = {
    unavailabilityConfig: UnavailabilityConfig
    handleSaveConfig: () => void
    handleCloseSnackbar: () => void
    snackbarConfig: SnackbarConfigTypes
    handleDefaultConfig: (params: HandleDefaultConfig) => void
    isLoadingConfig: boolean
    isSavingConfig: boolean
}

export default function UnavailabilityConfigView({
    unavailabilityConfig,
    handleSaveConfig,
    handleCloseSnackbar,
    snackbarConfig,
    handleDefaultConfig,
    isLoadingConfig,
    isSavingConfig,
}: UnavailabilityConfigViewProps): JSX.Element {
    return (
        <GlobalThemeProvider>
            <PageContainer>
                <ConfigContainer>
                    <ConfigLabel formLabel>
                        CONFIGURAR INDISPONIBILIDADE DO SISTEMA
                    </ConfigLabel>

                    {isLoadingConfig ? (
                        <>
                            <Skeleton
                                data-testid="loading-skeleton"
                                variant="rectangular"
                                height={200}
                                animation="wave"
                            />

                            <Skeleton
                                data-testid="loading-skeleton"
                                variant="rectangular"
                                width="80%"
                                height={60}
                                animation="wave"
                            />
                        </>
                    ) : (
                        <DateContainer>
                            <Header>
                                <RowContent>
                                    <RowItem>Mês</RowItem>
                                    <RowItem>Início intervalo</RowItem>
                                    <RowItem>Final intervalo</RowItem>
                                </RowContent>
                            </Header>
                            <Row>
                                <RowContent>
                                    <RowItem>
                                        Período de indisponibilidade{' '}
                                        <Tooltip text="Indica o período de indisponibilidade para emissão de pedidos no app. Selecione a data e a hora para que o bloqueio dentro do período seja efetuado." />
                                    </RowItem>
                                    <RowItem>
                                        <CustomDatePicker
                                            format="DD/MM/YYYY HH:mm"
                                            useTime
                                            label="De"
                                            defaultValue={dayjs(
                                                unavailabilityConfig.period
                                                    .start
                                            )}
                                            onChange={(value) => {
                                                handleDefaultConfig({
                                                    period: 'start',
                                                    value: value.valueOf(),
                                                })
                                            }}
                                        />
                                    </RowItem>
                                    <RowItem>
                                        <CustomDatePicker
                                            format="DD/MM/YYYY HH:mm"
                                            useTime
                                            label="Até"
                                            defaultValue={dayjs(
                                                unavailabilityConfig.period.end
                                            )}
                                            onChange={(value) => {
                                                handleDefaultConfig({
                                                    period: 'end',
                                                    value: value.valueOf(),
                                                })
                                            }}
                                        />
                                    </RowItem>
                                </RowContent>
                            </Row>
                        </DateContainer>
                    )}
                </ConfigContainer>

                <Footer>
                    <WarningText>
                        *Salve as informações após as alterações realizadas. O
                        não salvamento implicará na perda das mesmas.
                    </WarningText>
                    <FormButtonsContainer>
                        <Link to="/">
                            <CancelButton variant="outlined" disabled={false}>
                                Cancelar
                            </CancelButton>
                        </Link>
                        <SaveButton
                            startIcon={
                                isSavingConfig && (
                                    <LoadingComponent size="small" />
                                )
                            }
                            onClick={handleSaveConfig}
                            disabled={isSavingConfig}
                            endIcon={<CheckIcon />}
                        >
                            Salvar
                        </SaveButton>
                    </FormButtonsContainer>
                </Footer>

                <SnackBarComponent
                    handleClose={handleCloseSnackbar}
                    isVisible={snackbarConfig.isVisible}
                    message={snackbarConfig.message}
                    severity={snackbarConfig.status}
                />
            </PageContainer>
        </GlobalThemeProvider>
    )
}

const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${COLORS.secondaryWhite};
`

const ConfigContainer = styled.div`
    display: flex;
    width: -webkit-fill-available;
    flex-direction: column;
    padding: 28px 23px 23px 22px;
    gap: 16px;
    background-color: ${COLORS.primaryWhite};
`
const DateContainer = styled.div`
    display: flex;
    flex-direction: column;
`
const Row = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: center;
    border-bottom: solid 1px ${COLORS.borderGray};
    background-color: ${COLORS.primaryWhite};
`
const RowItem = styled.div`
    display: flex;
    flex-direction: row;
    width: 30%;
    justify-content: center;
    align-items: center;
`
const Header = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    row-gap: 16px;
    border-radius: 3px;

    justify-content: center;

    background-color: ${COLORS.borderGray};
`

const RowContent = styled.div`
    display: flex;
    flex-direction: row;
    width: 70%;
    padding: 10px 0px 10px;
    font-size: 14px;
    font-family: Roboto;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: 0.09px;
    justify-content: space-between;
    color: ${COLORS.primaryGray};
`

const ConfigLabel = styled.span<{
    formLabel?: boolean
}>`
    flex-grow: 0;
    font-family: Roboto;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: 0.09px;
    text-align: left;
    color: ${COLORS.primaryGray};
    text-transform: uppercase;
    ${({ formLabel }) =>
        formLabel &&
        `
        margin: 0px 0px 2px;
    `}
`
const FormButtonsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 250px;
`

const Footer = styled.div`
    display: flex;
    width: -webkit-fill-available;
    justify-content: space-between;
    gap: 16px;
    align-items: center;
    padding: 0 10px 30px 20px;
    margin-top: 30px;
`

const SaveButton = materialStyled(Button)({
    fontSize: 14,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.71,
    letterSpacing: 'normal',
    backgroundColor: COLORS.primaryGreen,
    borderColor: COLORS.primaryGreen,
    color: COLORS.primaryWhite,
    fontFamily: 'Roboto',
    '&:hover': {
        backgroundColor: COLORS.secondaryGreen,
        borderColor: COLORS.secondaryGreen,
        boxShadow: 'none',
    },
    '&:disabled': {
        backgroundColor: COLORS.disabledButton,
        borderColor: COLORS.disabledButton,
        color: COLORS.primaryWhite,
        boxShadow: 'none',
    },
})

const CancelButton = materialStyled(Button)({
    fontSize: 14,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.71,
    letterSpacing: 'normal',
    fontFamily: 'Roboto',
})

const WarningText = styled.span`
    color: ${COLORS.primaryGray};
    font-size: 12px;
`

import dayjs, { Dayjs } from 'dayjs'
import { useState, useEffect } from 'react'

import { SnackbarConfigTypes } from '../../../commons/utils/Types'
import * as PermissionsAPI from '../../AppPermissionsAPI'
import NfeEmissionConfigView from './NfeEmissionConfigView'
import { InformationKey, Informations, DateConfig } from './utils/types'

export default function NfeEmissionConfigContainer() {
    const [informations, setInformations] = useState<Informations>({
        dataToFill: '',
        emailToSend: '',
        emailToCopy: '',
        bodyFields: '',
        additionalInfo: '',
    })
    const [dateConfig, setDateConfig] = useState<DateConfig>({
        end: '',
        start: '',
    })
    const [snackbarConfig, setSnackBarConfig] = useState<SnackbarConfigTypes>({
        message: '',
        isVisible: false,
        status: 'success',
    })
    const [isLoading, setIsLoading] = useState<boolean>(true)

    const loadingConfig = async () => {
        try {
            setIsLoading(true)
            const {
                data: { poPeriod, ...config },
            } = await PermissionsAPI.getNfeEmissionConfig()

            setDateConfig(poPeriod)
            setInformations(config)
        } catch (error) {
            setSnackBarConfig((prev) => ({
                ...prev,
                isVisible: true,
                message: `Erro ao buscar configuração, por favor tente mais tarde!`,
                status: 'error',
            }))
            console.error(error)
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        loadingConfig()
    }, [])

    const handleChangeInformation = (value: string, name: string) => {
        setInformations((prev: Informations) => ({
            ...prev,
            [name]: value,
        }))
    }

    const handleCustomDateConfig = ({ period, value }: HandleConfigDay) => {
        const endDate = new Date(dateConfig.end)
        const startDate = new Date(dateConfig.start)

        if (period === 'end' && value.isBefore(startDate)) {
            setDateConfig({
                [period]: value.toDate().toDateString(),
                start: dayjs(value).startOf('month').toDate().toDateString(),
            })
        }

        if (period === 'start' && value.isAfter(endDate)) {
            setDateConfig({
                [period]: value.toDate().toDateString(),
                end: dayjs(value).endOf('month').toDate().toDateString(),
            })
        }

        setDateConfig((prev) => {
            return {
                ...prev,
                [period]: value.toDate().toDateString(),
            }
        })
    }

    const handleGetInformation = (objectKey: string) =>
        informations[objectKey as InformationKey]

    const handleSetNfeEmissionConfig = async () => {
        try {
            setIsLoading(true)
            const regex = /^[^@]+@[^@]+\.[^@]+$/
            if (
                !regex.test(informations.emailToCopy) ||
                !regex.test(informations.emailToSend)
            )
                throw new Error(
                    `Deve ser informado um e-mail válido para ${
                        !regex.test(informations.emailToCopy)
                            ? 'o campo de e-mail a ser copiado no envio'
                            : 'o campo de e-mail vinculado ao envio'
                    }`
                )

            await PermissionsAPI.setNfeEmissionConfig({
                poPeriod: dateConfig,
                ...informations,
            })
            loadingConfig()
            setSnackBarConfig((prev) => ({
                ...prev,
                isVisible: true,
                message: `Configurações salvas com sucesso!`,
                status: 'success',
            }))
        } catch (error) {
            console.log(error)
            const err = error as Error
            const errorMessage = err?.message.includes(
                'Deve ser informado um e-mail válido'
            )
                ? err.message
                : 'Erro ao salvar as configurações! Tente novamente mais tarde.'

            setSnackBarConfig((prev) => ({
                ...prev,
                isVisible: true,
                message: errorMessage,
                status: 'error',
            }))
        } finally {
            setIsLoading(false)
        }
    }

    const handleCloseSnackbar = () => {
        setSnackBarConfig((prev) => ({ ...prev, isVisible: false }))
    }

    return (
        <NfeEmissionConfigView
            onChangeInformations={handleChangeInformation}
            getInformationValue={handleGetInformation}
            informations={informations}
            handleCustomDateConfig={handleCustomDateConfig}
            dateConfig={dateConfig}
            onPressSetNfeEmissionConfig={handleSetNfeEmissionConfig}
            snackbarConfig={snackbarConfig}
            handleCloseSnackbar={handleCloseSnackbar}
            isLoading={isLoading}
            onPressCancel={loadingConfig}
        />
    )
}

export type HandleConfigDay = {
    period: 'end' | 'start'
    value: Dayjs
}

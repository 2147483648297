import InfoIcon from '@mui/icons-material/Info'
import { useState } from 'react'
import styled from 'styled-components'

import { COLORS } from '../Colors'

type TooltipProps = {
    text: string
}

export default function Tooltip({ text }: TooltipProps) {
    const [hover, setHover] = useState<boolean>(false)

    return (
        <FlexContainer
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
        >
            <InfoIcon
                sx={{
                    color: COLORS.thirdGray,
                    height: 15,
                    width: 15,
                    margin: 1,
                }}
            />
            {hover && <InfoContainer>{text}</InfoContainer>}
        </FlexContainer>
    )
}

const FlexContainer = styled.div`
    display: flex;
`

const InfoContainer = styled.div`
    background-color: ${COLORS.primaryGray};
    border-radius: 5px;
    color: ${COLORS.primaryWhite};
    font-size: 12px;
    letter-spacing: 0.1px;
    margin: 22px 0 0 10px;
    opacity: 0.6;
    padding: 11px;
    position: absolute;
    line-height: 22px;
    font-weight: normal;
    width: 279px;
`

import styled from 'styled-components'

import { COLORS } from '../../commons/Colors'
import { ROUTES } from '../../commons/utils/AppRoutes'
import DsmTabCard from './DsmTabCard'

const Tabs = [
    {
        title: 'Permissionamentos',
        path: ROUTES.APPPERMISSIONS,
    },
    {
        title: 'Regras comerciais',
        path: ROUTES.COMMERCIALRULES,
    },
]

export default function MainDsmContent(): JSX.Element {
    return (
        <MainContainer>
            <PageTitle>
                BEM VINDOS AO PORTAL ADMINISTRATIVO - TIME DSM FIRMENICH
            </PageTitle>
            <TabContainer>
                {Tabs.map(({ title, path }) => {
                    return <DsmTabCard key={title} title={title} path={path} />
                })}
            </TabContainer>
        </MainContainer>
    )
}

const MainContainer = styled.div`
    background-color: ${COLORS.primaryWhite};
    border: solid 1px ${COLORS.borderGray};
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    margin: 30px;
    padding: 25px 25px 50px 25px;
`
const PageTitle = styled.h1`
    align: center;
    color: ${COLORS.primaryGray};
    font-size: 12px;
    font-family: Roboto;
    font-weight: bold;
    text-align: center;
    margin-bottom: 70px;
`
const TabContainer = styled.div`
    display: flex;
`
